body {
  background-color: #f7f4ef;
  overflow: hidden;
}

.layout {
  width: 100%;
  padding-top: env(safe-area-inset-top);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.hc-header {
  height: 3rem;
  width: 100vw;
  z-index: 100;
  padding-top: env(safe-area-inset-top);
  background-image: -webkit-linear-gradient(#fdfcfa, #f0e8de);
  background-image: linear-gradient(#fdfcfa, #f0e8de);
  border-bottom: 4px solid #e2d5c7;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-box-shadow: inset 0 -2px rgba(247, 247, 247, .63), 0 1px 3px rgba(0, 0, 0, .29);
  -moz-box-shadow: inset 0 -2px rgba(247, 247, 247, .63), 0 1px 3px rgba(0, 0, 0, .29);
  box-shadow: inset 0 -2px rgba(247, 247, 247, .63), 0 1px 3px rgba(0, 0, 0, .29);
}

.hc-header--dent {
  width: 3.5rem;
  height: 2.7rem;
  -webkit-tap-highlight-color: transparent;
  background-image: -webkit-linear-gradient(135deg, #e7dbd0, #f5ede6);
  background-image: linear-gradient(135deg, #e7dbd0, #f5ede6);
  -webkit-border-radius: 1.5rem 0 0;
  -moz-border-radius: 1.5rem 0 0;
  border-radius: 1.5rem 0 0;
  display: block;
  position: absolute;
  bottom: .3rem;
  right: 0;
  -webkit-box-shadow: inset 2px 2px 4px rgba(227, 219, 207, .35);
  -moz-box-shadow: inset 2px 2px 4px rgba(227, 219, 207, .35);
  box-shadow: inset 2px 2px 4px rgba(227, 219, 207, .35);
}

.hc-header--dent:active, .hc-header--dent:focus {
  outline: none;
  -webkit-box-shadow: inset 2px 2px 8px rgba(0, 0, 0, .25);
  -moz-box-shadow: inset 2px 2px 8px rgba(0, 0, 0, .25);
  box-shadow: inset 2px 2px 8px rgba(0, 0, 0, .25);
}

.hc-header--icon {
  width: 2rem;
  height: 2rem;
  text-indent: -9000px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  bottom: .4rem;
}

.hc-header--icon:active, .hc-header--icon:focus {
  -webkit-filter: brightness(125%);
  filter: brightness(125%);
  outline: none;
}

.hc-header--icon__back {
  background-image: url("icon_back.001fc66d.svg");
  background-size: 1.1rem;
  left: .5rem;
}

.hc-header--icon__close {
  background-image: url("icon_close.4e996d19.svg");
  background-size: 1.4rem;
  bottom: 40%;
  right: 50%;
  -webkit-transform: translate(50%, 50%);
  -moz-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.community-iframe {
  padding: 3.25rem env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  width: 100% !important;
  height: calc(100vh - 48px) !important;
  border: none !important;
}

.hide {
  visibility: hidden;
}

/*# sourceMappingURL=index.a62fb624.css.map */
