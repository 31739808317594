body {
  background-color: rgb(247, 244, 239);
  overflow: hidden;
}

.layout {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: env(safe-area-inset-top);
  overflow: auto;
}

.hc-header {
  position: fixed;
  top: 0rem;
  left: 0rem;
  display: block;
  height: 3rem;
  width: 100vw;
  z-index: 100;
  padding-top: env(safe-area-inset-top);
  background-image: linear-gradient(to bottom, #fdfcfa, #f0e8de);
  border-bottom: 4px solid #e2d5c7;
  box-shadow: inset 0 -2px 0 0 rgba(247, 247, 247, 0.63), 0 1px 3px 0 rgba(0, 0, 0, 0.29);
}

.hc-header--dent {
  position: absolute;
  bottom: 0.3rem;
  right: 0;
  display: block;
  width: 3.5rem;
  height: 2.7rem;
  background-image: linear-gradient(135deg, #e7dbd0, #f5ede6);
  border-radius: 1.5rem 0 0 0;
  box-shadow: inset 2px 2px 4px 0 rgba(227, 219, 207, 0.35);
  -webkit-tap-highlight-color: transparent;
}

.hc-header--dent:active,
.hc-header--dent:focus {
  outline: none;
  box-shadow: inset 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.hc-header--icon {
  position: absolute;
  bottom: 0.4rem;
  display: block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  text-indent: -9000px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.hc-header--icon:active,
.hc-header--icon:focus {
  outline: none;
  filter: brightness(125%);
}

.hc-header--icon__back {
  background-image: url('./assets/icon_back.svg');
  background-size: 1.1rem;
  left: 0.5rem;
}

.hc-header--icon__close {
  background-image: url('./assets/icon_close.svg');
  background-size: 1.4rem;
  right: 50%;
  bottom: 40%;
  transform: translate(50%, 50%);
}

.community-iframe {
  padding: 3.25rem env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  width: 100% !important;
  height: calc(100vh - 48px) !important;
  border: none !important;
}

.hide {
  visibility: hidden;
}
